import React from 'react'

import HiddenLayout from '../../../../components/HiddenLayout'
import queryString from 'query-string'
import '../../../../components/fullPageCss.sass'
import storage from '../../../../lib/storage'

export default class AdminNavbar extends React.Component {
  state = {}
  componentDidMount() {
    this.setState({
      link: queryString.parse(location.search).link,
    })
  }
  render() {
    const link = `https://insights.collegepulse.com/vault/question/5de5349a3dee1b00133b7bb4/methodology?report&token=${storage.get(
      'token'
    )}`
    return (
      <HiddenLayout>
        <iframe title="brand_infogram" src={link} className="full-page-iframe noHeader" />
      </HiddenLayout>
    )
  }
}
